<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>
              {{ cardTitle }}
              <span v-if="filingBook"> - {{ filingBook.name }}</span>
            </h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filingCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  <v-select
                    v-model="typeFilter"
                    :items="['Minden típus', ...Object.values(types)]"
                  ></v-select>
                </th>
                <th>
                  <v-select
                    v-model="mediaFilter"
                    :items="['Minden típus', ...Object.values(mediums)]"
                  ></v-select>
                </th>
                <th>
                  <export-excel
                    v-if="json_fields.length != 0"
                    class="btn"
                    :data="json_data"
                    :fields="json_fields"
                    worksheet="iktato"
                    :name="exportName"
                  >
                    <v-btn color="primary" dark style="width: 100%!import">
                      {{ $t("FORMS.excel_export") }}
                    </v-btn>
                  </export-excel>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <FilingModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                :filingBook="filingBook"
                @new="handleNew"
              ></FilingModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="statuses.filings">
              {{
                $t("STATUSES." + statuses.filings.filings[item.status].value)
              }}
            </span>
          </template>

          <template v-slot:item.filing_date="{ item }">
            {{ item.filing_date.substring(0, 10) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <!-- <router-link
              :to="`/settings/filings/filings/${item.id}`"
              v-if="permissionCan('view')"
            >
              <v-icon small class="mr-2"> mdi-pencil </v-icon>
            </router-link> -->
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import FilingModalForm, { initialFormData } from "./FilingModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import ApiService from "@/core/services/api.service";

export const PERMISSION_TO = "filings.filing.";

export default {
  name: "Filings",
  components: { FilingModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.filings"),
      search: "",
      routePath: "/settings/filings/filings/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        { text: "Azonosító", value: "filing_number" },
        {
          text: "Tárgy",
          value: "subject",
        },
        {
          text: "Ügyfél neve",
          value: "partner_name",
        },
        {
          text: "Dokumentum típusa",
          value: "type",
          filter: (value) => {
            if (this.typeFilter == "Minden típus") return true;
            return value == this.typeFilter;
          },
        },
        {
          text: "Adathordozó típusa",
          value: "medium",
          filter: (value) => {
            if (this.mediaFilter == "Minden típus") return true;
            return value == this.mediaFilter;
          },
        },
        { text: "Iktatás dátuma", value: "filing_date" },
        { text: "Status", value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      filingCollection: [],
      types: {
        incoming: "Bejövő",
        outgoing: "Kimenő",
        internal: "Belső",
      },

      mediums: {
        paper: "Papír alapú",
        electronic: "Elektronikus",
      },

      filingBook: null,

      mediaFilter: "Minden típus",
      typeFilter: "Minden típus",

      json_fields: {
        Iktatókönyv: "filing_book",
        Azonosító: "filing_number",
        Iktatta: "user",
        Tárgy: "subject",
        "Dokumentum típusa": "type",
        "Ügyfél neve": "partner_name",
        "Adathordozó típusa": "medium",
        Ügyitéző: "administrator",
        "Dokumentum URL": "document_url",
        Megjegyzés: "comment",
        "Iktatás dátuma": "filing_date",
        Érkeztetés: "postage_date",
        Status: "status",
      },
      exportName: "iktato.xls",
    };
  },

  computed: {
    ...mapGetters([
      "getFilingBookByID",
      "statuses",
      "userCollection",
      "getUserByID",
    ]),

    endPoint() {
      return "filings/admin/filing/";
    },

    json_data() {
      return this.filingCollection.map((filing) => {
        let obj = {};

        obj.filing_book = this.filingBook.name;
        obj.user = this.getUserByID(filing.user_id).fullName;
        obj.subject = filing.subject;
        obj.type = filing.type;
        obj.filing_date = filing.filing_date;
        obj.postage_date = filing.postage_date;
        obj.partner_name = filing.partner_name;
        obj.filing_number = filing.filing_number;
        obj.type = filing.type;
        obj.medium = filing.medium;
        obj.administrator = filing.administrator_id
          ? this.getUserByID(filing.administrator_id)["fullName"]
          : filing.administrator_name;

        obj.document_url = filing.document_url;
        (obj.comment = filing.comment),
          (obj.status = this.$t(
            "STATUSES." + this.statuses.filings.filings[filing.status].value
          ));
        return obj;
      });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchFiling", "fetchUser"]),
    fetchModel() {
      this.loadingTable = true;
      this.fetchUser();
      let filingBookId = this.$route.params.filingBookId;

      ApiService.get("filings/admin/filingBook/" + filingBookId)
        .then(({ data }) => {
          this.filingBook = data;
          this.filingCollection = data.filings.map((item) => {
            item.medium = this.mediums[item.medium];
            item.type = this.types[item.type];
            return item;
          });
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
  },

  mounted() {
    this.routePath = this.$router.currentRoute.path;

    this.loadingTable = true;
    this.fetchModel();
  },
};
</script>
