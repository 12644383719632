var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.cardTitle)+" "),(_vm.filingBook)?_c('span',[_vm._v(" - "+_vm._s(_vm.filingBook.name))]):_vm._e()])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filingCollection,"search":_vm.search,"sort-by":['id'],"sort-desc":"true","loading":_vm.loadingTable,"footer-props":{
          'items-per-page-options': [100],
        }},on:{"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th'),_c('th'),_c('th'),_c('th',[_c('v-select',{attrs:{"items":['Minden típus' ].concat( Object.values(_vm.types))},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}})],1),_c('th',[_c('v-select',{attrs:{"items":['Minden típus' ].concat( Object.values(_vm.mediums))},model:{value:(_vm.mediaFilter),callback:function ($$v) {_vm.mediaFilter=$$v},expression:"mediaFilter"}})],1),_c('th',[(_vm.json_fields.length != 0)?_c('export-excel',{staticClass:"btn",attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"worksheet":"iktato","name":_vm.exportName}},[_c('v-btn',{staticStyle:{"width":"100%!import"},attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.excel_export"))+" ")])],1):_vm._e()],1)])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('FilingModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses,"endPoint":_vm.endPoint,"filingBook":_vm.filingBook},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}}),_c('v-spacer'),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(_vm.statuses.filings)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("STATUSES." + _vm.statuses.filings.filings[item.status].value))+" ")]):_vm._e()]}},{key:"item.filing_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.filing_date.substring(0, 10))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }